import create from "zustand";
import { ILoginValues, IUser } from "api/User/User.types";
import UserApi from "api/User/User.api";

const useUserStore = create<AppStoreState>((set, get) => ({
  user: undefined,
  loadingUser: false,
  userExistsChecked: false,

  login: async (values: ILoginValues) => {
    set({ loadingUser: true });
    let user = await UserApi.login(values);
    let toSet: any = { loadingUser: false };
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
      toSet["user"] = user;
    }
    set(toSet);
    return user;
  },

  logout: () => {
    let toSet = { user: undefined };
    localStorage.removeItem("user");
    set(toSet);
  },

  checkUserExists: () => {
    let user = localStorage.getItem("user");
    let toSet: any = { userExistsChecked: true };
    if (user) {
      toSet.user = JSON.parse(user);
    }
    set(toSet);
  },

  setLoadingUser: (loadingUser: boolean) => set({ loadingUser }),
  setUser: (user: IUser) => set({ user }),
}));

interface AppStoreState {
  user?: IUser;
  loadingUser: boolean;
  login: (values: ILoginValues) => any;
  logout: () => void;
  checkUserExists: () => void;
  setLoadingUser: (loadingUser: boolean) => void;
  setUser: (user: IUser) => void;
  userExistsChecked: boolean;
}

export default useUserStore;
