import "./styles.scss";

interface Props {
  text: string;
}

function LoaderDefault(props: Props) {
  const { text } = props;

  return (
    <div className="default-loader">
      <div className="loading-text">{text}</div>
    </div>
  );
}

export default LoaderDefault;
