import axios from "axios";
import { QueryClient } from "react-query";

/*** --- APP SETTINGS --- ***/
export const APP_NAME = 'agl_portal';
export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL
export const SECOND_API_URL = process.env.REACT_APP_SECOND_API_URL
export const LIBRARIAN_API_URL = process.env.REACT_APP_LIBRARIAN_API_URL
const apiUrls = [BASE_API_URL, SECOND_API_URL, LIBRARIAN_API_URL];
export const apiClients = apiUrls.map((baseUrl) => axios.create({ baseURL: baseUrl }));

axios.defaults.baseURL = BASE_API_URL;
apiClients[2].interceptors.request.use((config) => {
  const user = localStorage.getItem('user');
  if (user) {
    if (!config.headers) {
      config.headers = {}; // Add a new headers object if it doesn't exist
    }
    const parsedUser = JSON.parse(user);
    config.headers['sessionId'] = parsedUser.sessionID;
  }
  return config;
});

/*** --- PERMISSIONS SETTINGS --- ***/
export enum ROLES {
  PUBLIC = "Public",
  CUSTOMER = "Customer",
  SHIPPER = "Shipper"
}

/*** --- UI SETTINGS --- ***/
export const TOAST_DURATION = 10;
export const ERROR_TOAST_DURATION = 20;
export const APPLICATION_DISPLAY_NAME = 'AGL Portal'

/*** --- LOCAL STORAGE SETTINGS --- ***/
export function getLocalStorageKey(key: string) {
  return `${APP_NAME}_${key}`;
}

/*** --- REACT QUERY SETTINGS --- ***/
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    }
  }
})
