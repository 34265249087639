import React from "react";
import {
  MantineProvider,
  ColorSchemeProvider,
  ColorScheme,
} from "@mantine/core";
import { useHotkeys, useLocalStorage } from "@mantine/hooks";
import { ModalsProvider } from "@mantine/modals";
import { NotificationsProvider } from "@mantine/notifications";
import { QueryClientProvider } from "react-query";

import AppRouting from "./app/App.routing";
import { queryClient } from "./settings";

const App: React.FC = () => {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: "mantine-color-scheme",
    defaultValue: "dark",
    getInitialValueInEffect: true,
  });

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  useHotkeys([["mod+J", () => toggleColorScheme()]]);

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        theme={{
          colorScheme,
          colors: {
            brand: [
              "#FFFFFF",
              "#FFFFFF",
              "#DBCECF",
              "#CEB5B7",
              "#C49C9F",
              "#BE8286",
              "#BD666C",
              "#C14750",
              "#C22E39",
              "#A1373F",
            ],
          },
          loader: "bars",
          primaryColor: "brand",
          fontFamily: "Open Sans",
        }}
      >
        <ModalsProvider>
          <NotificationsProvider>
            <QueryClientProvider client={queryClient}>
              <AppRouting />
            </QueryClientProvider>
          </NotificationsProvider>
        </ModalsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
};

export default App;
