import axios from "axios";
import { ILoginValues, IUser } from "./User.types";

export default class UserApi {
  static async login(values: ILoginValues) {
    try {
      let res = await axios.post(`/Auth/Login`, { Username: values.username, Password: values.password });
      return res.data as IUser;
    } catch (e: any) {
      console.error('Could not login user: ', e.message);
      return undefined;
    }
  }
  static async logout() {
    localStorage.removeItem("user");
    return true;
  }
}
