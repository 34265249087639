import { useEffect, Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { LoadingOverlay } from "@mantine/core";

import useUserStore from "data/User.store";
import LoaderDefault from "components/common/Loader.default/Loader.default";

const AppLayout = lazy(() => import("./AppLayout"));
const Auth = lazy(() => import("containers/Auth/Auth"));
const AuthenticationImage = lazy(
  () => import("components/common/Auth.login/Auth.login.image")
);
const AgentBase = lazy(() => import("containers/Agent.base/Agent.base"));
const AgentSubmission = lazy(
  () => import("containers/Agent.submission.form/Agent.submission.form")
);
const AgentSubmissionUpdate = lazy(
  () => import("containers/Agent.submission.form/Agent.submission.update")
);
const Dashboard = lazy(() => import("containers/Dashboard/Dashboard"));
const CreateBooking = lazy(
  () => import("containers/CreateBooking/CreateBooking")
);
const RatesBase = lazy(() => import("containers/Rates.base/Rates.base"));
const RatesForApproval = lazy(
  () => import("containers/RatesForApproval/RatesForApproval")
);
const AgentReviewSearch = lazy(
  () => import("containers/Agent.review.search/Agent.review.search")
);
const AgentReviewForm = lazy(
  () => import("containers/Agent.review.form/Agent.review.form")
);
const ShipmentTracking = lazy(
  () => import("containers/ShipmentTracking/ShipmentTracking")
);
const ShipmentQueryResults = lazy(
  () => import("containers/ShipmentQueryResults/ShipmentQueryResults")
);
const ShippingTimelines = lazy(
  () => import("containers/Shipping.timelines/Shipping.Timelines")
);
const FinancialDashboard = lazy(() => import("containers/FinancialDashboard"));
const FinancialChargeDetails = lazy(
  () => import("containers/FinancialDashboard/ChargeDetails")
);
const CustomerProfiles = lazy(
  () => import("containers/CustomerProfiles/CustomerProfiles")
);
const CustomReports = lazy(() => import("containers/CustomReports"));
const ExportInvoicingReports = lazy(
  () => import("containers/CustomReports/ExportInvoicingReports")
);
const RateMatrix = lazy(() => import("containers/RateMatrix/RateMatrix"));
const MarketUpdates = lazy(
  () => import("containers/MarketUpdates/MarketUpdates")
);
const ImportReports = lazy(
  () => import("containers/ImportReports/ImportReports")
);

// {/* <Route path="rates" element={<ProtectedRoutes/>}> */}
// this works so far but it is hard coded in the ProtectedRoutes
// so try to figure out how to make it not hard-coded
// /rates/approvals
interface IProtectedRouteProps {
  isAllowed: boolean;
  redirectPath?: string;
  children?: any;
}

const ProtectedRoute = ({
  isAllowed,
  redirectPath = "/dashboard",
  children,
}: IProtectedRouteProps) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

//should be controlling all data
function AppRouting() {
  const { user, checkUserExists, userExistsChecked } = useUserStore();

  useEffect(() => {
    if (!userExistsChecked) {
      checkUserExists();
    }
    //eslint-disable-next-line
  }, []);

  if (!userExistsChecked) {
    return <LoaderDefault text="Loading application..." />;
  }

  function permissionExists(permission: string) {
    if (user?.permissions) {
      return user?.permissions.indexOf(permission) !== -1;
    } else {
      return false;
    }
  }

  return (
    <Router>
      <Suspense fallback={<LoadingOverlay visible />}>
        <Routes>
          {!user ? (
            <>
              <Route path="auth" element={<Auth />}>
                <Route path="login" element={<AuthenticationImage />} />
              </Route>
              <Route path="agent" element={<AgentBase />}>
                <Route path="submission" element={<AgentSubmission />} />
                <Route path="update" element={<AgentSubmissionUpdate />} />
                <Route
                  path="*"
                  element={<Navigate replace to="/agent/submission" />}
                />
              </Route>
              <Route path="*" element={<Navigate replace to="/auth/login" />} />
            </>
          ) : (
            <>
              <Route path="/" element={<AppLayout />}>
                <Route index element={<Navigate replace to="dashboard" />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route
                  path="rates"
                  element={
                    <ProtectedRoute
                      isAllowed={permissionExists("Rates For Approval")}
                    >
                      <RatesBase />
                    </ProtectedRoute>
                  }
                >
                  <Route path="approvals" element={<RatesForApproval />} />
                </Route>
                <Route
                  path="bookings"
                  element={
                    <ProtectedRoute
                      isAllowed={permissionExists("Booking for Approval")}
                    >
                      <div>Rates</div>
                    </ProtectedRoute>
                  }
                >
                  <Route path="approvals" element={<div>Bookings</div>} />
                </Route>
                <Route
                  path="create-booking"
                  element={
                    <ProtectedRoute
                      isAllowed={permissionExists("Create Booking")}
                    >
                      <CreateBooking />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="agent"
                  element={
                    <ProtectedRoute
                      isAllowed={permissionExists("Agent Review")}
                    />
                  }
                >
                  <Route path="review" element={<AgentReviewSearch />}>
                    <Route path=":agentId" element={<AgentReviewForm />} />
                  </Route>
                </Route>
                <Route
                  path="shipment-tracking"
                  element={
                    <ProtectedRoute
                      isAllowed={permissionExists("Shipment Tracking")}
                    >
                      <ShipmentTracking />
                    </ProtectedRoute>
                  }
                >
                  <Route path="shipments" element={<ShipmentQueryResults />}>
                    <Route
                      path=":shipmentNumber"
                      element={<ShippingTimelines />}
                    />
                  </Route>
                </Route>
                <Route
                  path="financial-dashboard"
                  element={
                    <ProtectedRoute
                      isAllowed={permissionExists("Financial Dashboard")}
                    >
                      <FinancialDashboard />
                    </ProtectedRoute>
                  }
                >
                  <Route
                    path="chargedetails"
                    element={<FinancialChargeDetails />}
                  />
                </Route>
                <Route
                  path="customer-profiles"
                  element={
                    <ProtectedRoute
                      isAllowed={permissionExists("Customer Profiles")}
                    >
                      <CustomerProfiles />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="custom-reports"
                  element={
                    <ProtectedRoute
                      isAllowed={permissionExists("Custom Reports")}
                    >
                      <CustomReports />
                    </ProtectedRoute>
                  }
                >
                  <Route
                    path="export-invoicing-reports"
                    element={<ExportInvoicingReports />}
                  />
                </Route>
                <Route path="rate-matrix" element={<RateMatrix />} />
                <Route path="market-updates" element={<MarketUpdates />} />
                <Route path="import-reports" element={<ImportReports />} />
              </Route>
              <Route path="*" element={<Navigate replace to="dashboard" />} />
            </>
          )}
        </Routes>
      </Suspense>
    </Router>
  );
}

function RequireAuth({ children, redirectTo }: any) {
  let isAuthenticated = localStorage.getItem("logged_in") === "true";
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
}

export default AppRouting;
